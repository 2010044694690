<template>
  <vue-final-modal
    class="modal-quest-rewards"
    :overlay-transition="{ mode: 'in-out', duration: 250 }"
    :content-transition="{ mode: 'in-out', duration: 250 }"
  >
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title">
        {{ rewardsModalTitle }}
      </div>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('quest-rewards')" />
      </div>
    </div>

    <div class="vfm__body">
      <div class="modal-quest-rewards__content">
        <div v-for="(item, index) in rewardsList" :key="index" class="modal-quest-rewards__item">
          <span class="modal-quest-rewards__amount">{{ item }}</span>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';

  const { closeModal } = useModalStore();
  const questsStore = useQuestsStore();
  const { rewardsList, rewardsModalTitle } = storeToRefs(questsStore);
</script>

<style src="~/assets/styles/components/modal/quest-rewards.scss" lang="scss" />
